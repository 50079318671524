import { cn } from "~/utils/helper";

export function FocusCircle({ className }: { className?: string }) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("text-[#93C8BF]", className)}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6195 25.9169V30.2317H15.0903V25.9169H16.6195ZM21.8797 24.0876L24.0238 27.824L22.6994 28.5931L20.5546 24.8567L21.8797 24.0876ZM9.831 24.0876L11.1561 24.8567L9.01131 28.5931L7.68698 27.824L9.831 24.0876ZM25.5245 19.858L29.2391 22.0154L28.4745 23.3475L24.7599 21.1901L25.5245 19.858ZM6.18543 19.858L6.95007 21.1901L3.23624 23.3475L2.47161 22.0154L6.18543 19.858ZM5.13163 14.3616V15.8998H0.842773V14.3616H5.13163ZM30.8678 14.3616V15.8998H26.5782V14.3616H30.8678ZM3.23655 6.91435L6.95041 9.07174L6.18574 10.4039L2.47192 8.24647L3.23655 6.91435ZM28.4742 6.91435L29.2388 8.24647L25.5242 10.4039L24.7595 9.07174L28.4742 6.91435ZM22.6992 1.66855L24.0243 2.43767L21.8795 6.17407L20.5551 5.40495L22.6992 1.66855ZM9.01155 1.66855L11.1556 5.40495L9.83124 6.17407L7.68644 2.43767L9.01155 1.66855ZM16.6195 0.0297852V4.34456H15.0903V0.0297852H16.6195Z"
      />
    </svg>
  );
}
