import { cn } from "~/utils/helper";

export default function StarSvg({ className }: { className?: string }) {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("text-[#FF6533]", className)}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.74018 12C7.79239 13.3178 10.2375 15.8691 11.5005 19.0539C12.7634 15.8691 15.2076 13.3178 18.2608 12C15.2076 10.6822 12.7634 8.13086 11.5005 4.94607C10.2375 8.13086 7.79239 10.6822 4.74018 12ZM12.4387 24H10.5622C10.5622 17.9231 5.82389 12.979 0 12.979V11.021H0.0356536C5.84359 11.0004 10.5622 6.0651 10.5622 0H12.4387C12.4387 6.07685 17.177 11.021 23 11.021V12.979H22.9643C17.1573 12.9996 12.4387 17.9349 12.4387 24Z"
      />
    </svg>
  );
}
